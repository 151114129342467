import React, { useEffect, useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Button from "../common/button/button";
import InputMask from "react-input-mask";
import ModalParkingZone from "./modalParkingZone";
import ModalLicensePlate from "./modalLicensePlate";
import { getCountryImages, getSwissCantonImages } from "../reusable/functions";
import { CLEAR_VISITOR_STATUS, SIGN_IN_VISITOR } from "../actions/types";
import ModalTodaysCode from "./modalTodaysCode";
import Group13 from "../common/assets/group13.svg";
import LabelWithHR from "../common/label/labelWithHR";
import ModalParkingZoneNotExist from "./modalParkingZoneNotExist";
import Style from "./userHome.module.css";
import ErrorModal from "../common/modal/errorModal";
import ModalWrongDailyCode from "./ModalWrongDailyCode";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import {
  checkIfLicensePlateHasReservationAlready,
  checkVisitor,
  checkVisitorLicensePlateInParkingZone,
  checkVisitorWithDailyCode,
  signInVisitorWithoutDailyCode,
} from "../actions/userHome";
import HeadingWithWelcomeLabel from "../common/label/headingWithWelcomeLabel";
import ReservationModal from "./reservationModal";
import ConfirmationModal from "../common/modal/confirmationModal";
import MobileHeader from "../common/header/mobileHeader";
import { useTranslation } from "react-i18next";

const UserHome = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [parkingZone, setParkingZone] = useState(null);
  const [licensePlate, setLicensePlate] = useState("");
  const [openModalParkingZone, setOpenModalParkingZone] = useState(false);
  const [openModalLicensePlate, setOpenModalLicensePlate] = useState(false);
  const [openModalTodaysCode, setOpenModalTodaysCode] = useState(false);
  const [modalWrongDailyCode, setModalWrongDailyCode] = useState(false);
  const [modalVisitorNotAllowed, setModalVisitorNotAllowed] = useState(false);
  const [modalAllreadySignedIn, setModalAllreadySignedIn] = useState(false);
  const [openModalNewReservation, setOpenModalNewReservation] = useState(false);
  const [modalReservation, setModalReservation] = useState(false);
  const [cancelPrevious, setCancelPrevious] = useState(false);
  const [openModalAlreadySignedIn, setOpenModalAlreadySignedIn] =
    useState(false);
  const [openModalForPausedParking, setOpenModalForPausedParking] =
    useState(false);
  const [openModalParkingZoneNotExist, setOpenModalParkingZoneNotExist] =
    useState(false);
  const [errorBlacklistModal, setErrorBlacklistModal] = useState(false);

  const {
    visitorStatusInZone,
    visitorAlreadySignedIn,
    visitorWaitingForFreezeTimeToExpire,
  } = useSelector((state) => state.userHome);

  useEffect(() => {
    if (params?.parkingZone?.length === 9 && params?.code?.length === 4) {
      axios
        .get(
          `${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/parkingZoneNumber/${params.parkingZone}/${params.code}`
        )
        .then((response) => {
          if (response.status === 404) {
            setOpenModalParkingZoneNotExist(true);
          } else {
            setParkingZone(response.data);
          }
        });
    }
  }, [params.parkingZone, params.code]);

  useEffect(() => {
    if (parkingZone && licensePlate) {
      dispatch(
        checkVisitorLicensePlateInParkingZone(
          licensePlate,
          parkingZone?.parkingZoneNumber
        )
      );
    }
  }, [parkingZone?.parkingZoneNumber, licensePlate]);

  useEffect(() => {
    if (params?.parkingZone?.length === 9 && !params.code) {
      axios
        .get(
          `${process.env.REACT_APP_SERVICE_URL}/api/parking-zone/parkingZoneNumber/${params.parkingZone}`
        )
        .then((response) => {
          if (response.status === 404) {
            setOpenModalParkingZone(false);
            setOpenModalParkingZoneNotExist(true);
          } else {
            setParkingZone(response.data);
          }
        });
    }
  }, [params.parkingZone]);

  useEffect(() => {
    if (
      visitorStatusInZone === "CHECKED_IN" ||
      visitorStatusInZone === "SIGN_IN_WITHOUT_DAILY_CODE"
    ) {
      history.push("/legalVisitor");
      dispatch({
        type: CLEAR_VISITOR_STATUS,
      });
    } else if (
      visitorStatusInZone === "VISITOR_NOT_CHECKED_IN" ||
      visitorStatusInZone === "NOT_CHECKED_IN"
    ) {
      setOpenModalTodaysCode(true);
      dispatch({
        type: CLEAR_VISITOR_STATUS,
      });
    }
  }, [visitorStatusInZone, history, dispatch]);

  const checkLicensePlate = async () => {
    if (parkingZone.signOutAfterFourHours) {
      const dailyCode = {
        name: "Guest",
        plateNumber: licensePlate.replaceAll("·", ""),
        parkingZoneId: parseInt(parkingZone?.parkingZoneNumber),
      };

      dispatch(
        signInVisitorWithoutDailyCode(
          dailyCode,
          history,
          setOpenModalAlreadySignedIn,
          setOpenModalForPausedParking
        )
      );
    } else if (!params.code) {
      dispatch(
        checkVisitor(
          licensePlate,
          parkingZone?.parkingZoneNumber,
          setErrorBlacklistModal,
          setModalVisitorNotAllowed,
          setModalAllreadySignedIn
        )
      );
    } else {
      const dailyCode = {
        name: "Guest",
        plateNumber: licensePlate.replaceAll("·", ""),
        dailyCode: parseInt(params.code),
        parkingZoneId: parseInt(parkingZone?.parkingZoneNumber),
      };
      dispatch(
        checkVisitorWithDailyCode(dailyCode, setModalWrongDailyCode, history)
      );
    }
  };

  const opetTodaysCodeModal = () => {
    setOpenModalTodaysCode(true);
  };

  const closeModal = () => {
    setOpenModalParkingZone(false);
    setOpenModalLicensePlate(false);
  };

  const closeEnterTodaysModal = () => {
    setOpenModalTodaysCode(false);
  };
  const reEnterParkingZone = () => {
    setOpenModalParkingZoneNotExist(false);
    setOpenModalParkingZone(true);
  };

  const closeNotExistParkingZoneModal = () => {
    setOpenModalParkingZoneNotExist(false);
  };

  const openNotExistParkingZoneModal = () => {
    setOpenModalParkingZone(false);
    setOpenModalParkingZoneNotExist(true);
  };

  return (
    isMobile && (
      <div className={Style.mainContainer}>
        <MobileHeader />
        <HeadingWithWelcomeLabel label={t("UserHome.Visitor login")} />
        <div className={Style.fullWidthUserHome}>
          <LabelWithHR label={t("UserHome.Parking zone")} />
          <div
            className={`${Style.inputMask} ${Style.spaceBetweenInputs}`}
            onClick={() => {
              setOpenModalParkingZone(true);
            }}
          >
            {parkingZone?.parkingZoneNumber ? (
              <p className={Style.inputFullfil}>
                {parkingZone?.parkingZoneNumber === ""
                  ? params.parkingZone
                  : parkingZone?.parkingZoneNumber}
              </p>
            ) : (
              <div className={Style.licensePlateButtonUserHome}>
                <img src={Group13} alt="Group13" width={"7%"} />
                <p className={Style.userHomeButtonText}>{t("Global.enter")}</p>
              </div>
            )}
          </div>

          <LabelWithHR label={t("UserHome.License plate")} />
          <div
            className={Style.inputMask}
            onClick={() => {
              setOpenModalLicensePlate(true);
            }}
          >
            {licensePlate === "" ? (
              <div className={Style.licensePlateButtonUserHome}>
                <img src={Group13} alt="Group13" width={"7%"} />

                <p className={Style.userHomeButtonText}>{t("Global.enter")}</p>
              </div>
            ) : (
              <div className={Style.licensePlateOuter}>
                <div className={Style.licensePlateMid}>
                  <div className={Style.licensePlateInner}>
                    {getSwissCantonImages(
                      licensePlate && licensePlate.slice(0, 2)
                    ) && (
                      <img
                        src={getCountryImages("CH")}
                        alt="countryImage"
                        className={Style.imageSwiss}
                      />
                    )}

                    <InputMask
                      name="licensePlate"
                      className={`${Style.inputMask} ${Style.licensePlateInput}`}
                      readOnly
                      value={
                        getSwissCantonImages(
                          licensePlate && licensePlate.slice(0, 2)
                        )
                          ? licensePlate.slice(0, 2) +
                            "·" +
                            licensePlate.slice(2, licensePlate.length)
                          : licensePlate
                      }
                    />
                    {getSwissCantonImages(
                      licensePlate && licensePlate.slice(0, 2)
                    ) && (
                      <img
                        src={getSwissCantonImages(
                          licensePlate && licensePlate.slice(0, 2)
                        )}
                        alt="cantonImage"
                        className={Style.imageCanton}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {parkingZone?.signOutAfterFourHours ? (
            <div
              style={{
                display: "flex",
                marginTop: "30px",
                justifyContent: "space-between",
              }}
            >
              <Button
                text={t("UserHome.Register")}
                buttonStyle={{ width: "150px" }}
                style={{ margin: "10px", marginLeft: "0px" }}
                disabled={
                  parkingZone?.parkingZoneNumber === "" ||
                  licensePlate === "" ||
                  visitorAlreadySignedIn
                }
                onClick={() => checkLicensePlate()}
              />
              <Button
                text={t("UserHome.Reservations")}
                buttonStyle={{ width: "150px" }}
                onClick={() =>
                  dispatch(
                    checkIfLicensePlateHasReservationAlready(
                      parkingZone?.parkingZoneNumber,
                      licensePlate,
                      setOpenModalNewReservation,
                      setModalReservation
                    )
                  )
                }
                style={{ margin: "10px", marginRight: "0px" }}
                disabled={
                  parkingZone?.parkingZoneNumber === "" ||
                  licensePlate === "" ||
                  visitorAlreadySignedIn
                }
              />
            </div>
          ) : (
            <Button
              text={t("Global.continue")}
              onClick={() => checkLicensePlate()}
              style={{ marginTop: "50px" }}
              disabled={
                parkingZone?.parkingZoneNumber === "" ||
                licensePlate === "" ||
                visitorAlreadySignedIn ||
                visitorWaitingForFreezeTimeToExpire
              }
            />
          )}
          {parkingZone && licensePlate && visitorAlreadySignedIn && (
            <span className={Style.disabledText}>
              {t("UserHome.vehicleRegistredAlreadyInfo")}
            </span>
          )}
          {parkingZone &&
            licensePlate &&
            visitorWaitingForFreezeTimeToExpire && (
              <span className={Style.disabledText}>
                {t("UserHome.vehicleWaitingForFreezeTimeToExpire")}
              </span>
            )}
        </div>
        {openModalParkingZone && (
          <ModalParkingZone
            open={openModalParkingZone}
            close={closeModal}
            setParkingZone={setParkingZone}
            setOpenModalParkingZoneNotExist={setOpenModalParkingZoneNotExist}
            defaultValue={parkingZone?.parkingZoneNumber}
            openNotExistParkingZoneModal={openNotExistParkingZoneModal}
          />
        )}
        {openModalLicensePlate && (
          <ModalLicensePlate
            open={openModalLicensePlate}
            close={closeModal}
            setLicensePlate={setLicensePlate}
            defaultValue={licensePlate}
          />
        )}

        {openModalTodaysCode && (
          <ModalTodaysCode
            open={openModalTodaysCode}
            licensePlate={licensePlate}
            parkingZone={parkingZone?.parkingZoneNumber}
            close={closeEnterTodaysModal}
            openWrongDailyCode={() => {
              setOpenModalTodaysCode(false);
              setModalWrongDailyCode(true);
            }}
          />
        )}
        {openModalParkingZoneNotExist && (
          <ModalParkingZoneNotExist
            open={openModalParkingZoneNotExist}
            closeParkingZoneModalNotExist={reEnterParkingZone}
            close={closeNotExistParkingZoneModal}
          />
        )}

        {errorBlacklistModal && (
          <ErrorModal
            title={t("Global.error")}
            errorMessage={t("UserHome.errorBlacklistModal")}
            open={errorBlacklistModal}
            onClose={() => setErrorBlacklistModal(false)}
            width="400px"
          />
        )}

        {modalVisitorNotAllowed && (
          <ErrorModal
            title={t("Global.error")}
            errorMessage={t("UserHome.errorVisitorNotAllowed")}
            open={modalVisitorNotAllowed}
            onClose={() => setModalVisitorNotAllowed(false)}
            width="400px"
          />
        )}
        {modalAllreadySignedIn && (
          <ErrorModal
            title={t("Global.error")}
            errorMessage={t("UserHome.errorVisitorAllreadySignedIn")}
            open={modalAllreadySignedIn}
            onClose={() => setModalAllreadySignedIn(false)}
            width="400px"
          />
        )}
        {openModalForPausedParking && (
          <ErrorModal
            title={t("Global.error")}
            errorMessage={t("UserHome.errorPausedParking")}
            open={openModalForPausedParking}
            onClose={() => setOpenModalForPausedParking(false)}
            width="400px"
          />
        )}

        {openModalAlreadySignedIn && (
          <ErrorModal
            title={t("Global.error")}
            errorMessage={t("UserHome.errorVisitorAllreadySignedIn")}
            open={openModalAlreadySignedIn}
            onClose={() => setOpenModalAlreadySignedIn(false)}
            width="400px"
          />
        )}

        {modalWrongDailyCode && (
          <ModalWrongDailyCode
            open={modalWrongDailyCode}
            reEnterCode={() => {
              setModalWrongDailyCode(false);
              opetTodaysCodeModal();
            }}
            close={() => {
              setModalWrongDailyCode(false);
            }}
          />
        )}
        {modalReservation && (
          <ReservationModal
            open={modalReservation}
            close={() => setModalReservation(false)}
            reservation={{
              parkingZoneNumber: parseInt(parkingZone.parkingZoneNumber),
              licensePlate: licensePlate,
              cancelPrevious: cancelPrevious,
            }}
          />
        )}
        {openModalNewReservation && (
          <ConfirmationModal
            title={t("UserHome.New reservation")}
            errorMessage={t("UserHome.New reservation question")}
            open={openModalNewReservation}
            onClose={() => setOpenModalNewReservation(false)}
            width="400px"
            leftButtonText={t("Global.no")}
            rightButtonText={t("Global.yes")}
            leftButtonAction={() => {
              setOpenModalNewReservation(false);
            }}
            rightButtonAction={() => {
              setModalReservation(true);
              setOpenModalNewReservation(false);
              setCancelPrevious(true);
            }}
          />
        )}

        <div className={Style.versionBox}>VERSION:{process.env.REACT_APP_VERSION}</div>
      </div>
    )
  );
};

export default UserHome;
