import axios from "axios";


export const generateConfirmationReport = (visitor) => async () => {
  const windowReference = window.open();

  axios
    .post(
      `${process.env.REACT_APP_SERVICE_URL}/api/tickets/generateConfirmation`,
      visitor,
      {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/pdf",
        }
      }
    )
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf"});

        const url = window.URL.createObjectURL(blob);

        // Open the PDF in a new tab
        windowReference.location = url;

        // Clean up
        setTimeout(() => {
            // document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }, 100);


    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {});
};
