import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import "dayjs/locale/de";
import { useTranslation } from "react-i18next";
import React, {ReactNode} from "react";

type ReservationModalWrapperProps = {
  children: ReactNode;
};

const ReservationModalWrapper = (props: ReservationModalWrapperProps) => {
  const { t } = useTranslation();
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={"de"}
      localeText={{
        cancelButtonLabel: t("Global.cancel"),
        okButtonLabel: "Ok",
      }}
    >
      {props.children}
    </LocalizationProvider>
  );
};
export default ReservationModalWrapper;
